import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { LessonHomeworkCompletion } from '@shared/services/lessons/dto/lesson.dto';

export interface ConductedLessonCommentData {
  lessonSubject: string;
  lessonId: string;
  goodNotes: string[];
  needAttention: string[];
  homeworkCompletion: LessonHomeworkCompletion
}

export interface GPTMessageResponse {
  message: string;
}

export interface ExpandTeachersConclusionParams {
  completionPercentage: string,
  studentId: string,
  dealId?: string,
  reportDate?: string;
  isDiagnostic?: boolean;
}

export interface ExpandTeachersRecommendationParams {
  completionPercentage: string,
  dealId?: string,
}

export enum AIGenerationStatus {
  GENERATED = 'generated',
  NOT_GENERATED = 'not_generated',
  EDITED_AFTER_GENERATION = 'edited_after_generation',
  SKIPPED = 'skipped'
}

@Injectable({
  providedIn: 'root'
})
export class GptService {
  protected http = inject(HttpClient);
  protected baseURL: string = environment.PifagorApiAddress;
  protected url: string = `${this.baseURL}/integrations/gpt`;

  public createConductedLessonComment(data: ConductedLessonCommentData): Observable<GPTMessageResponse> {
    return this.http.post<GPTMessageResponse>(`${this.url}/conducted-lesson-comment`, { ...data })
  }

  public askQuestion(message: string): Observable<GPTMessageResponse> {
    return this.http.post<GPTMessageResponse>(`${this.url}`, { message })
  }

  public expandTeachersNote(message: string): Observable<GPTMessageResponse> {
    return this.http.post<GPTMessageResponse>(`${this.url}/expand-teachers-note`, { message })
  }

  public expandTeachersConclusion(
    message: string,
    options: ExpandTeachersConclusionParams,
  ): Observable<GPTMessageResponse> {
    return this.http.post<GPTMessageResponse>(`${this.url}/generate-teachers-conclusion`, {
      message,
      ...options,
    })
  }

  public expandTeachersRecommendation(
    message: string,
    options: ExpandTeachersConclusionParams,
  ): Observable<GPTMessageResponse> {
    return this.http.post<GPTMessageResponse>(`${this.url}/generate-teachers-recommendation`, {
      message,
      ...options,
    })
  }
}
