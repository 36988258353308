import MaterialDto from './material.dto';
import TeacherDto from './teacher.dto';
import StudentDto from '@shared/services/students/dto/student.dto';
import { AIGenerationStatus } from '@shared/services/gpt.service';

export enum ReportType {
  REGULAR = 'regular',
  DIAGNOSTIC = 'diagnostic',
}

export default interface ReportDto {
  id: string;
  createdAt: string;
  type: ReportType;
  teacherId: string;
  studentId: string;
  links: string[] | null;
  knowledge: string | null;
  knowledgeAiStatus: AIGenerationStatus;
  gaps: string | null;
  gapsAiStatus: AIGenerationStatus;
  conclusion: string | null;
  conclusionAiStatus: AIGenerationStatus;
  recommendation: string | null;
  recommendationAiStatus: AIGenerationStatus;
  completionPercentage: string | null;
  materials: MaterialDto[];
  teacher: TeacherDto;
  student: StudentDto;
}
