import { Component, inject } from '@angular/core';
import { NzColDirective } from 'ng-zorro-antd/grid';
import { NzFormControlComponent, NzFormDirective, NzFormItemComponent, NzFormLabelComponent } from 'ng-zorro-antd/form';
import { NzAutosizeDirective, NzInputDirective } from 'ng-zorro-antd/input';
import {
  ConductLessonFormService
} from '@modules/components/schedule/calendar/modals/conduct-lesson-modal/conduct-lesson-form.service';
import { TranslatePipe } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NzRadioComponent, NzRadioGroupComponent } from 'ng-zorro-antd/radio';
import { LessonHomeworkCompletion } from '@shared/services/lessons/dto/lesson.dto';

@Component({
  selector: 'math-lesson-info',
  standalone: true,
  imports: [
    NzColDirective,
    NzFormControlComponent,
    NzFormItemComponent,
    NzFormLabelComponent,
    NzInputDirective,
    TranslatePipe,
    NzAutosizeDirective,
    ReactiveFormsModule,
    NzFormDirective,
    NzRadioComponent,
    NzRadioGroupComponent,
  ],
  templateUrl: './lesson-info.component.html',
  styleUrls: ['./lesson-info.component.scss'],
})
export class LessonInfoComponent {
  protected conductLessonFormService = inject(ConductLessonFormService);
  protected readonly HomeWorkCompletion = LessonHomeworkCompletion;
}
