import { BaseHttpService } from '@shared/abstractions/base-http.service';
import { Injectable } from '@angular/core';
import LessonDto, { RateLessonDto } from '@shared/services/lessons/dto/lesson.dto';
import { FutureLessonsPagedQueryDto } from '@shared/services/lessons/dto/future-lessons-paged-query.dto';
import { SkipLessonBodyDto, SkipLessonResponseDto } from '@shared/services/lessons/dto/skip-lesson.dto';

@Injectable({
  providedIn: 'root',
})
export default class LessonsApiService extends BaseHttpService<LessonDto> {
  constructor() {
    super('lessons');
  }

  public getById(id: string) {
    return this.get(id);
  }

  public updateById(id: string, formData: FormData) {
    return this.put(id, formData);
  }

  public getAllFuturePaged(query: FutureLessonsPagedQueryDto) {
    return this.getPaged<FutureLessonsPagedQueryDto, LessonDto>('future', query);
  }

  public rateLessonById(id: string, body: RateLessonDto) {
    return this.put<RateLessonDto, void>(`${id}/rate`, body);
  }

  public skipLessonById(id: string, body: SkipLessonBodyDto) {
    return this.post<SkipLessonBodyDto, SkipLessonResponseDto>(`${id}/skip`, body);
  }
}
