import {
  Component,
  EventEmitter,
  inject,
  input,
  OnInit,
  Output,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { timeSlotDiapasonToTimeString } from '@shared/utils';
import LanguageService from '@shared/language/language.service';
import { StepInterface } from '@shared/components/customized-stepper/step.interface';
import { ConductLessonFormService } from '@modules/components/schedule/calendar/modals/conduct-lesson-modal/conduct-lesson-form.service';
import {
  ConductLessonStepNames,
  ConductLessonSteps,
} from './const/conduct-lesson-step.const';
import { AutoUnsubscribeComponent } from '@shared/components/auto-unsubscribe/auto-unsubscribe.component';

@Component({
  selector: 'math-conduct-lesson-modal',
  templateUrl: './conduct-lesson-modal.component.html',
  styleUrls: ['./conduct-lesson-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConductLessonModalComponent
  extends AutoUnsubscribeComponent
  implements OnInit
{
  public lessonId = input<string>();
  public isConductInsteadSkip = input<boolean>();
  public editMode = input<boolean>();
  private visitedStep: number = 0;

  protected readonly conductLessonFormService = inject(
    ConductLessonFormService
  );
  private readonly languageService = inject(LanguageService);

  protected readonly formSteps: StepInterface[] = ConductLessonSteps;
  protected readonly ConductLessonStepNames = ConductLessonStepNames;

  @Output() onSuccess = new EventEmitter<void>();
  @Output() onCancel = new EventEmitter<void>();

  protected activeStep = signal<number | null>(0);

  constructor() {
    super();
  }

  public ngOnInit(): void {
    this.trackFormChanges();
    this.conductLessonFormService.initForm(this.lessonId(), this.editMode());
  }

  public formatDate(dateString: string): string {
    const date = new Date(dateString);
    const formatter = Intl.DateTimeFormat(this.languageService.locale, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });

    return formatter.format(date);
  }

  public timeSlotToTime(timeSlots: number[]): string {
    return timeSlotDiapasonToTimeString(timeSlots);
  }

  protected back(): void {
    if (this.activeStep() === ConductLessonStepNames.LESSON_INFO) {
      this.onCancel.emit();
    } else {
      this.activeStep.update((prev) => --prev);
    }
  }

  protected next(): void {
    if (this.activeStep() === ConductLessonStepNames.ADDITIONAL_INFO) {
      this.conductLessonFormService
        .onSubmit(this.lessonId(), this.isConductInsteadSkip())
        .pipe(take(1))
        .subscribe(() => {
          this.onSuccess.emit();
          this.conductLessonFormService.lessonForm.reset();
        });
    } else {
      this.activeStep.update((prev) => ++prev);

      if (this.activeStep() > this.visitedStep)
        this.visitedStep = this.activeStep();
    }
  }

  protected onSwitchStep(targetStep: number) {
    if (targetStep <= this.visitedStep || this.editMode()) {
      this.activeStep.set(targetStep);
    }
  }

  protected showHeaderError(): boolean {
    return (
      this.activeStep() >= ConductLessonStepNames.NEED_ATTENTION &&
      this.conductLessonFormService.isInvalidStep(this.activeStep())
    );
  }

  private trackFormChanges(): void {
    this.conductLessonFormService.lessonForm.controls.teacherRates.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.conductLessonFormService.isChangedRates = true;
      });
  }
}
