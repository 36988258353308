import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ScheduleCalendarActiveSlot } from '../schedule/calendar/schedule-calendar.service';
import { MonthlyCalendarDay, MonthlyCalendarItem } from '../schedule/schedule-api.service';
import LanguageService from '@shared/language/language.service';
import { DealInfoFormExtra, UpsertDealRequest } from './deal-api.service';
import DealDto from '../schedule/dto/deal.dto';
import { minutesToHours, timeSlotDiapasonToTimeString, timeStringToTimeSlotDiapason } from '@shared/utils';
import { isActiveRegularLesson, isRegularLesson, isReservedRegularLesson } from '../schedule/schedule-utils';
import { LessonStatus, LessonType } from '@shared/services/lessons/dto/lesson.dto';
import { InvoicePaymentStatus, InvoiceProduct } from '../schedule/dto/invoice.dto';

interface DealScheduleMetrics {
  nextReservations: number;
  firstLessonDate: string;
  lastLessonDate: string;
  setHours: number;
  totalHours: number;
  reservedLessons: number;
  conductedLessons: number;
}

const defaultScheduleMetrics = {
  nextReservations: 0,
  firstLessonDate: '-',
  lastLessonDate: '-',
  setHours: 0,
  totalHours: 0,
  reservedLessons: 0,
  conductedLessons: 0,
};

@Injectable({
  providedIn: 'root',
})
export default class DealService {

  constructor(
    private readonly languageService: LanguageService,
  ) {
  }

  public activeSlot = new BehaviorSubject<ScheduleCalendarActiveSlot>(null);
  public activeLessonDuration = new BehaviorSubject<number>(null);
  public activeDealId = new BehaviorSubject<string>(null);
  public activeDealPastSubDealId = new BehaviorSubject<string>(null);
  public activeDealFormState = new BehaviorSubject<{ isValid: boolean; formValue: Partial<DealDto> & DealInfoFormExtra }>(null);
  public dealCalendarDays = new BehaviorSubject<Record<string, MonthlyCalendarDay>>(null);
  public newDealCalendarDays = new Subject<MonthlyCalendarDay[]>();
  public isShowOnlyFreeSlots = new BehaviorSubject<boolean>(true);
  public isScheduleChangesDetected = new BehaviorSubject<boolean>(false);
  public reservationsIdsToRemove = new BehaviorSubject<string[]>([]);
  public scheduleMetrics = new BehaviorSubject<DealScheduleMetrics>(defaultScheduleMetrics);

  public reset(): void {
    this.activeSlot.next(null);
    this.activeLessonDuration.next(null);
    this.activeDealId.next(null);
    this.activeDealPastSubDealId.next(null);
    this.activeDealFormState.next(null);
    this.dealCalendarDays.next(null);
    this.newDealCalendarDays.next(null);
    this.scheduleMetrics.next(defaultScheduleMetrics);
    this.isShowOnlyFreeSlots.next(true);
    this.isScheduleChangesDetected.next(false);
    this.reservationsIdsToRemove.next([]);
  }

  public getRequestDataFromDealCalendarDays(): UpsertDealRequest {
    const days = this.dealCalendarDays.value;
    const reservationsToCreate: UpsertDealRequest['reservationsToCreate'] = [];
    const futureSubReservationsToCreate: UpsertDealRequest['futureSubReservationsToCreate'] = [];

    for (const { items } of Object.values(days)) {
      const reservations = items
        .filter(item => item.isConfirmedReservation && !item.isLessonReservation && !item.markedForFutureSubscription)
        .map(item => ({
          date: item.date,
          timeSlots: timeStringToTimeSlotDiapason(item.time),
        }));

      reservationsToCreate.push(...reservations);

      const futureSubReservations = items
        .filter(item => item.isConfirmedReservation && !item.isLessonReservation && item.markedForFutureSubscription)
        .map(item => ({
          date: item.date,
          timeSlots: timeStringToTimeSlotDiapason(item.time),
        }));

      futureSubReservationsToCreate.push(...futureSubReservations);
    }

    return {
      reservationsToCreate,
      futureSubReservationsToCreate,
    }
  }

  public setupDealScheduleMetrics(deal: DealDto): void {
    const reservedLessons = deal.lessons.filter((lesson) => isReservedRegularLesson(lesson) && !lesson.markedForFutureSubscription).length;
    const conductedLessons = deal.lessons.filter((lesson) => isRegularLesson(lesson) && lesson.isConducted).length;
    const nextReservations = deal.lessons.filter((lesson) =>
      lesson.markedForFutureSubscription && lesson.status !== LessonStatus.CANCELED).length;
    const setHours = deal.lessons
      .filter((lesson) => (isActiveRegularLesson(lesson) && !lesson.markedForFutureSubscription))
      .map((lesson) => lesson.timeSlot.length / 12)
      .reduce((acc, total) => acc + total, 0);

    const lessonsDates = deal.lessons
      .filter((lesson) => isActiveRegularLesson(lesson) && !lesson.markedForFutureSubscription)
      .map((lesson) => lesson.date)
      .sort();

    const metrics: Partial<DealScheduleMetrics> = {
      reservedLessons,
      conductedLessons,
      nextReservations,
      setHours,
      firstLessonDate: '-',
      lastLessonDate: '-',
    };

    let firstLessonDate = lessonsDates[0];
    let lastLessonDate = lessonsDates[lessonsDates.length - 1];

    if (firstLessonDate) {
      metrics.firstLessonDate = this.formatDate(new Date(firstLessonDate));
    }

    if (lastLessonDate) {
      metrics.lastLessonDate = this.formatDate(new Date(lastLessonDate));
    }

    this.updateScheduleMetrics(metrics);
  }

  public updateBorderLessonsDates(): void {
    let firstLessonDate = '-';
    let lastLessonDate = '-';

    const days = this.dealCalendarDays.value;
    let dates = Object.keys(days).sort();

    const isLessonOrReservation = (item: MonthlyCalendarItem) => {
      if (item.isLesson && ['reserved', 'booked'].includes(item.status) && item.type !== LessonType.FIRST) {
        return item.dealId === this.activeDealId.value;
      }

      if ((item.isMarkedForReservation || item.isLessonReservation || item.isConfirmedReservation) && !item.markedForFutureSubscription) {
        return true;
      }

      return false;
    };

    for (const date of dates) {
      const reservation = days[date].items?.find(isLessonOrReservation);

      if (reservation) {
        firstLessonDate = this.formatDate(new Date(reservation.date));
        break;
      }
    }

    for (const date of dates.reverse()) {
      const reservation = days[date].items?.find(isLessonOrReservation);

      if (reservation) {
        lastLessonDate = this.formatDate(new Date(reservation.date));
        break;
      }
    }

    this.updateScheduleMetrics({
      firstLessonDate,
      lastLessonDate,
    });
  }

  public updateScheduleMetrics(newValue: Partial<DealScheduleMetrics>): void {
    const currentValue = this.scheduleMetrics.value;
    this.scheduleMetrics.next({
      ...currentValue,
      ...newValue,
    });
  }

  public formatDate(date: Date): string {
    const formatter = new Intl.DateTimeFormat(this.languageService.locale, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });

    const [day, month, year] = formatter.format(date).split(' ');
    return [day, month, year].join(' ');
  }

  /* Schedule utils */
  public removeSlotWithMergingItems(itemToRemove: MonthlyCalendarItem, dayItems: MonthlyCalendarItem[]): {
    newItems: MonthlyCalendarItem[],
    hoursRemoved: number,
  } {
    const removeIds = [itemToRemove.id];
    const itemToRemoveDiapason = timeStringToTimeSlotDiapason(itemToRemove.time);
    const itemToRemoveIdx = dayItems.findIndex(item => item.id === itemToRemove.id);

    let prevItem = dayItems[itemToRemoveIdx - 1];
    if (prevItem && !prevItem.isLesson && !prevItem.isConfirmedReservation) {
      const prevItemDiapason = timeStringToTimeSlotDiapason(prevItem.time);
      if (prevItemDiapason[prevItemDiapason.length - 1] !== itemToRemoveDiapason[0] - 1) {
        prevItem = undefined;
      }
    } else {
      prevItem = undefined;
    }

    let nextItem = dayItems[itemToRemoveIdx + 1];
    if (nextItem && !nextItem.isLesson && !nextItem.isConfirmedReservation) {
      const nextItemDiapason = timeStringToTimeSlotDiapason(nextItem.time);
      if (nextItemDiapason[0] !== itemToRemoveDiapason[itemToRemoveDiapason.length - 1] + 1) {
        nextItem = undefined;
      }
    } else {
      nextItem = undefined;
    }

    let mergedItem: MonthlyCalendarItem = {
      id: crypto.randomUUID(),
      date: itemToRemove.date,
      time: itemToRemove.time,
      isUIGeneratedSlot: true,
    };

    if (prevItem) {
      const prevItemDiapason = timeStringToTimeSlotDiapason(prevItem.time);

      if (itemToRemoveDiapason[0] - prevItemDiapason[prevItemDiapason.length - 1] === 1) {
        removeIds.push(prevItem.id);
        mergedItem.time = timeSlotDiapasonToTimeString([...prevItemDiapason, ...timeStringToTimeSlotDiapason(mergedItem.time)]);
      }
    }

    if (nextItem) {
      const nextItemDiapason = timeStringToTimeSlotDiapason(nextItem.time);

      if (nextItemDiapason[0] - itemToRemoveDiapason[itemToRemoveDiapason.length - 1] === 1) {
        removeIds.push(nextItem.id);
        mergedItem.time = timeSlotDiapasonToTimeString([...timeStringToTimeSlotDiapason(mergedItem.time), ...nextItemDiapason]);
      }
    }

    let hoursRemoved = minutesToHours(itemToRemoveDiapason.length * 5);

    dayItems.splice(prevItem ? itemToRemoveIdx - 1 : itemToRemoveIdx, removeIds.length, mergedItem);

    return {
      newItems: dayItems,
      hoursRemoved,
    };
  }

  public getPrevAndNextFutureItems(
    futureItem: MonthlyCalendarItem,
    futureItems: MonthlyCalendarItem[],
  ): [MonthlyCalendarItem, MonthlyCalendarItem] {
    const affectedSlot = this.getFreeSlotItemForReservationItem(futureItem.time, futureItems);

    if (!affectedSlot) {
      return [null, null];
    }

    const futureItemDiapason = timeStringToTimeSlotDiapason(futureItem.time);
    const affectedSlotDiapason = timeStringToTimeSlotDiapason(affectedSlot.time);
    let prevItem: MonthlyCalendarItem = null;
    let nextItem: MonthlyCalendarItem = null;

    const prevItemDiapason = affectedSlotDiapason.filter(el => el < futureItemDiapason[0]);
    if (prevItemDiapason.length) {
      prevItem = {
        ...affectedSlot,
        time: timeSlotDiapasonToTimeString(prevItemDiapason),
        id: crypto.randomUUID(),
        isDisabled: !futureItem.isConfirmedReservation,
        isUIGeneratedSlot: true,
      }
    }

    const nextItemDiapason = affectedSlotDiapason.filter(el => el > futureItemDiapason[futureItemDiapason.length - 1]);
    if (nextItemDiapason.length) {
      nextItem = {
        ...affectedSlot,
        time: timeSlotDiapasonToTimeString(nextItemDiapason),
        id: crypto.randomUUID(),
        isDisabled: !futureItem.isConfirmedReservation,
        isUIGeneratedSlot: true,
      }
    }

    return [prevItem, nextItem];
  }

  public getFreeSlotItemForReservationItem(itemTime: string, dayItems: MonthlyCalendarItem[]): MonthlyCalendarItem {
    const itemDiapason = timeStringToTimeSlotDiapason(itemTime).join('');

    for (const dayItem of dayItems) {
      const dayItemDiapason = timeStringToTimeSlotDiapason(dayItem.time).join('');

      if (!dayItem.isLesson && dayItemDiapason.includes(itemDiapason)) {
        return dayItem;
      }
    }

    return null;
  }

  public getLessonForReservationItem(itemTime: string, dayItems: MonthlyCalendarItem[]): MonthlyCalendarItem {
    const itemDiapason = timeStringToTimeSlotDiapason(itemTime);

    for (const dayItem of dayItems) {
      if (dayItem.isLesson || dayItem.isLessonReservation || dayItem.isConfirmedReservation) {
        const dayItemDiapason = timeStringToTimeSlotDiapason(dayItem.time);

        for (const timeSlot of dayItemDiapason) {
          if (itemDiapason.includes(timeSlot)) {
            return dayItem;
          }
        }
      }
    }

    return null;
  }

  public isNMTDeal(deal: DealDto): boolean {
    const invoices = deal.invoices.filter((invoice) => (invoice.paymentStatusId !== InvoicePaymentStatus.CANCELED));
    if (invoices && invoices.length > 0) {
      if (invoices.filter((invoice) => invoice.productId === InvoiceProduct.NMT_SUBSCRIPTION)?.length > 0
        && invoices.every((invoice) => [
          InvoiceProduct.FIRST_LESSON,
          InvoiceProduct.FREE_LESSON,
          InvoiceProduct.NMT_SUBSCRIPTION,
        ].includes(invoice.productId))) return true;
    }
    return false;
  }
}
