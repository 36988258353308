import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnInit, Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import LanguageService from '@shared/language/language.service';
import ReportDto, { ReportType } from '../../../dto/report.dto';
import { AuthService } from '../../../../auth/auth.service';
import ScheduleApiService from '../../../schedule-api.service';
import ScheduleCalendarService from '../../schedule-calendar.service';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { UploadMaterialsComponent } from '@shared/components/upload-materials/upload-materials.component';
import {
  ExpandTeachersConclusionParams,
  ExpandTeachersRecommendationParams,
  GptService
} from '@shared/services/gpt.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'math-report-modal',
  templateUrl: './report-modal.component.html',
  styleUrls: ['./report-modal.component.scss'],
  providers: [GptService]
})
export class ReportModalComponent implements OnInit {
  protected firstLessonId: string;
  private reportId: string;
  private studentId: string;
  private dealId: string;
  protected editMode: boolean;

  @Output() onSuccess = new EventEmitter<void>();
  @Output() onCancel = new EventEmitter<void>();

  private teacherId: string;

  public isLoading = false;

  public reportType: typeof ReportType= ReportType;

  public reportData$: Observable<Partial<ReportDto>>;
  public loadingError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public reportForm: UntypedFormGroup = new UntypedFormGroup({
    knowledge: new UntypedFormControl('', [Validators.required, Validators.minLength(100)]),
    knowledgeAiStatus: new UntypedFormControl(),
    gaps: new UntypedFormControl('', [Validators.required, Validators.minLength(100)]),
    gapsAiStatus: new UntypedFormControl(),
    conclusion: new UntypedFormControl('', [Validators.required, Validators.minLength(100)]),
    conclusionAiStatus: new UntypedFormControl(),
    recommendation: new UntypedFormControl(''),
    recommendationAiStatus: new UntypedFormControl(),
    completionPercentage: new UntypedFormControl('', Validators.maxLength(255)),
    type: new UntypedFormControl(''),
    links: new UntypedFormArray([], Validators.maxLength(5)),
  });

  @ViewChild('linkInputElement', { static: false }) linkInputElement?: ElementRef;
  public linkInputVisible = false;
  public linkInputValue = '';

  @ViewChild(UploadMaterialsComponent) mathUpload: UploadMaterialsComponent | undefined;

  protected readonly expandTeachersNoteRequest = this.gptService.expandTeachersNote.bind(this.gptService);
  protected readonly expandTeachersConclusionRequest = this.gptService.expandTeachersConclusion.bind(this.gptService);
  protected readonly expandTeachersRecommendationRequest = this.gptService.expandTeachersRecommendation.bind(this.gptService);
  protected expandTeachersConclusionOptions: ExpandTeachersConclusionParams;
  protected expandTeachersRecommendationOptions: ExpandTeachersRecommendationParams;
  protected readonly ReportType = ReportType;

  constructor(
    private readonly scheduleApiService: ScheduleApiService,
    private readonly languageService: LanguageService,
    private readonly translateService: TranslateService,
    private readonly authService: AuthService,
    private readonly scheduleCalendarService: ScheduleCalendarService,
    private readonly gptService: GptService,
    @Optional() @Inject(NZ_MODAL_DATA) public data: {
      firstLessonId: string,
      reportId: string,
      editMode: boolean,
      studentId: string,
      dealId: string,
    }
  ) {
    if (data?.firstLessonId) this.firstLessonId = data.firstLessonId;
    if (data?.reportId) this.reportId = data.reportId;
    if (data?.editMode) this.editMode = data.editMode;
    if (data?.studentId) this.studentId = data.studentId;

    if (data.dealId) this.dealId = data.dealId;

    this.studentId = data.studentId;

    this.updateAiRequestOptions();
  }

  public ngOnInit(): void {
    this.loadingError$.next(false);

    if (!this.editMode) {
      const type = this.firstLessonId ? ReportType.DIAGNOSTIC : ReportType.REGULAR;
      this.reportData$ = of({
        createdAt: new Date().toISOString(),
        type,
      });

      if (type === ReportType.DIAGNOSTIC) {
        this.reportForm.controls['completionPercentage'].setValidators(Validators.required);
        this.reportForm.controls['recommendation'].setValidators(Validators.required);
      }

      return;
    }

    this.reportData$ = this.scheduleApiService.getReportById(this.reportId).pipe(
      tap(report => {
        this.teacherId = report.teacherId;
        this.expandTeachersConclusionOptions.reportDate = report.createdAt;

        this.reportForm.patchValue(report);

        if (report.type === ReportType.DIAGNOSTIC) {
          this.reportForm.controls['completionPercentage'].setValidators(Validators.required);
          this.reportForm.controls['recommendation'].setValidators(Validators.required);
        }

        this.reportForm.markAllAsTouched();

        if (report.links?.length) {
          for (const link of report.links) {
            this.links.push(new UntypedFormControl(link));
          }
        }

        requestAnimationFrame(() => {
          if (report.materials?.length) {
            this.mathUpload.fileList = report.materials.map(el => ({
              id: el.id,
              uid: el.externalId,
              name: `${el.externalId}.${el.extension}`,
              url: el.src,
              currentlyUploaded: true,
            }));
          }
        });
      }),
      catchError(() => {
        this.loadingError$.next(true);
        return EMPTY;
      }),
    );
  }


  private updateAiRequestOptions(): void {
    this.expandTeachersConclusionOptions = {
      studentId: this.studentId,
      completionPercentage: '',
      dealId: this.dealId,
      isDiagnostic: !!this.firstLessonId,
    };

    this.expandTeachersRecommendationOptions = {
      completionPercentage: '',
      dealId: this.dealId,
    }

    this.reportForm.get('completionPercentage').valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe((res: string) => {
        this.expandTeachersConclusionOptions.completionPercentage = res;
        this.expandTeachersRecommendationOptions.completionPercentage = res;
      })
  }

  public onSubmit(): void {
    const formData = new FormData();
    const filesToUpload = this.mathUpload.fileList.filter(file => !file.currentlyUploaded);

    if (filesToUpload.length) {
      for (const file of filesToUpload) {
        formData.append('files', file as any);
      }
    }

    const body = {
      ...this.reportForm.value,
      filesIdsToRemove: this.mathUpload.filesIdsToRemove.filter(Number.isInteger),
      teacherId: this.authService.user?.teacherId || this.teacherId || this.scheduleCalendarService.activeTeacher?.value?.id,
      studentId: this.studentId,
      type: this.firstLessonId ? ReportType.DIAGNOSTIC : ReportType.REGULAR,
    };

    if (this.editMode) {
      body.type = this.reportForm.value.type;
    }

    formData.append('body', JSON.stringify(body));

    this.isLoading = true;

    let request: Observable<void>;

    if (this.firstLessonId) {
      request = this.scheduleApiService.conductFirstLesson(this.firstLessonId, formData);
    } else if (this.editMode && this.reportId) {
      request = this.scheduleApiService.editReport(this.reportId, formData);
    } else {
      request = this.scheduleApiService.createReport(formData);
    }

    request
      .pipe(finalize(() => { this.isLoading = false; }))
      .subscribe(() => {
        this.onSuccess.emit();
      });
  }

  get links(): UntypedFormArray {
    return this.reportForm.get('links') as UntypedFormArray;
  }

  public showLinkInput(): void {
    this.linkInputVisible = true;
    setTimeout(() => {
      this.linkInputElement?.nativeElement?.focus();
    }, 10);
  }

  public handleLinkInputConfirm(): void {
    if (this.linkInputValue.length > 255) {
      this.linkInputValue = '';
      this.linkInputVisible = false;
      return;
    }

    if (this.linkInputValue && this.links.controls.map(el => el.value).indexOf(this.linkInputValue) === -1) {
      this.links.push(new UntypedFormControl(this.linkInputValue));
    }

    this.linkInputValue = '';
    this.linkInputVisible = false;
  }

  public onRemoveLink(index): void {
    this.links.removeAt(index);
  }

  public sliceLinkValue(link: string): string {
    const isLongLink = link.length > 15;
    return isLongLink ? `${link.slice(0, 15)}...` : link;
  }

  public formatDate(date: string): string {
    const formatter = Intl.DateTimeFormat(this.languageService.locale, {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });

    return formatter.format(new Date(date.split('T')[0]));
  }

  protected isAllowedConclusionGenerateWithEmptyInput(): boolean {
    return !!this.reportForm.get('knowledge').value.length
      && !!this.reportForm.get('gaps').value.length
      && !!this.reportForm.get('completionPercentage').value.length;
  }

  protected get preRequestConclusionText(): string {
    let text =  '';

    if (!this.firstLessonId) {
      text += this.translateService.instant('calendar.report.conclusion-regular-default');
    }

    text += `- knowledge: ${this.reportForm.get('knowledge').value}
             - gaps: ${this.reportForm.get('gaps').value}`;

    return text;
  }
}
