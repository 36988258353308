@if (fileList.length) {
  <nz-form-label nzFor="materials">
    {{ 'main.materials.label' | translate }}
  </nz-form-label>
}

@if (fileList.length) {
  <div class="materials">
    <nz-upload
      [(nzFileList)]="fileList"
      [nzShowButton]="false"
      nzListType="picture-card"
      [nzPreview]="handleImagePreview"
      [nzRemove]="handleImageRemove"
      [nzPreviewIsImage]="isImage"
    >
    </nz-upload>
  </div>
}

<nz-upload
  [nzBeforeUpload]="beforeImageUpload"
  [nzPreview]="handleImagePreview"
  [nzShowButton]="fileList.length < 5"
  (nzChange)="handleUploadImageChange($event)"
  [nzMultiple]="true"
  [nzPreviewIsImage]="isImage"
  [nzLimit]="5">
  <div class="upload-controls">
    <button nz-button
            class="upload-button">
      {{ 'main.materials.upload' | translate }}
    </button>

    <div class="upload-info">
      {{ 'main.materials.upload-tip' | translate }}
    </div>
  </div>
</nz-upload>

<nz-modal
  [nzVisible]="isImagePreviewVisible"
  [nzContent]="modalContent"
  [nzFooter]="null"
  (nzOnCancel)="isImagePreviewVisible = false">
  <ng-template #modalContent>
    <img alt="" [src]="imagePreview" [ngStyle]="{ width: '100%' }" />
  </ng-template>
</nz-modal>
