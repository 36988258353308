<div class="field-wrapper">
  <div class="textarea-wrapper">
    <textarea
      nz-input
      nzAutosize
      [value]="value"
      [placeholder]="placeholder()"
      [maxLength]="maxLength()"
      (input)="value = $event.currentTarget['value']"
      (change)="checkForEmpty(); writeValue($event.currentTarget['value'])"
      rows="3"
    ></textarea>

    @if (showLoader()) {
      <div class="loader">
        {{ 'ai_textarea.generating' | translate }}
      </div>
    }
  </div>

  <div class="counter">
    {{ value.length }}
  </div>

  <div class="navigate-buttons">
    @if (isAiGenerated()) {
      <a nz-button
         nzType="link"
         (click)="prevValue()"
         [disabled]="!isPrevEnabled">
        <span nz-icon
              nz-tooltip
              [nzTooltipTitle]="'ai.back-to-origin' | translate"
              nzType="undo"
              nzTheme="outline">
        </span>
      </a>

      <a nz-button
         nzType="link"
         nz-tooltip
         [nzTooltipTitle]="'ai.go-to-generated' | translate"
         (click)="nextValue()"
         [disabled]="!isNextEnabled"
      >
        <span nz-icon
              nzType="redo"
              nzTheme="outline">
        </span>
      </a>
    }

    <a nz-button
       nzType="link"
       class="ai-generate"
       nz-tooltip
       [nzTooltipTitle]="'ai.expand-input' | translate"
       (click)="onAiClick()"
       [disabled]="isGenerationBlocked">
      <span nz-icon>
        <img
          src="/assets/icons/matheema-ai-green.svg">
      </span>
    </a>
  </div>
</div>
