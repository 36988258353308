import { ChangeDetectorRef, Component, HostListener, inject } from '@angular/core';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { NzModule } from '@modules/nz-module';
import { NgStyle } from '@angular/common';
import { NotificationService } from '@shared/services/notification.service';

@Component({
  selector: 'math-upload-materials',
  standalone: true,
  imports: [
    NzModule,
    TranslatePipe,
    NgStyle
  ],
  templateUrl: './upload-materials.component.html',
  styleUrl: './upload-materials.component.scss'
})
export class UploadMaterialsComponent {

  public readonly cdRef = inject(ChangeDetectorRef);
  private readonly notificationService = inject(NotificationService);
  private readonly translateService = inject(TranslateService);

  public fileList: NzUploadFile[] = [];
  public filesIdsToRemove: number[] = [];
  public imagePreview: string | undefined = '';
  public isImagePreviewVisible = false;

  private readonly supportedFileTypes: string[] = [
    'image/png',
    'image/jpeg',
    'image/webp',
    'application/pdf',
  ];
  private readonly oneKb: number = 1000;

  @HostListener('document:keydown.control.v')
  public onPasteByCtrlV() {
    this.handleClickPaste();
  }

  @HostListener('document:keydown.control.м')
  public onPasteByCtrlVCyrillic() {
    this.handleClickPaste().then();
  }

  @HostListener('document:keydown.meta.v')
  public onPasteByCommandV() {
    this.handleClickPaste().then();
  }

  @HostListener('document:keydown.meta.м')
  public onPasteByCommandVCyrillic() {
    this.handleClickPaste().then();
  }

  public addFile(files: NzUploadFile[]): void {
    this.fileList.push(...files)
  }

  public handleUploadImageChange(change: NzUploadChangeParam): void {
    const { file } = change;

    if (file.status === 'removed' && file.currentlyUploaded) {
      this.filesIdsToRemove.push(file.id);
    }
  }

  public handleImageRemove = (file: NzUploadFile): boolean => {
    this.filesIdsToRemove.push(file.id);
    return true;
  };

  public handleImagePreview = async (file: NzUploadFile): Promise<void> => {
    if (file.type === 'application/pdf' || file.name.endsWith('.pdf')) {
      return;
    }

    this.imagePreview = file.url;
    this.isImagePreviewVisible = true;
  };

  public isImage = (file: NzUploadFile): boolean => {
    if (file.name.endsWith('.pdf')) {
      return false;
    }

    return file.type !== 'application/pdf';
  };

  private async handleClickPaste(): Promise<void> {
    const clipboardItems = await navigator.clipboard.read();

    if (!clipboardItems.length) {
      this.notificationService.showInfoMessage(
        this.translateService.instant(
          'main.materials.upload-err-by-empty-buffer'
        )
      );
      return;
    }

    const [item] = clipboardItems;

    if (item.types.find((type) => type === 'text/plain')) {
      return;
    }

    const foundImageMimeType = item.types.find((type: string) =>
      this.supportedFileTypes.includes(type)
    );

    if (foundImageMimeType) {
      const blob = await item.getType(foundImageMimeType);
      const uid = crypto.randomUUID();
      const file = new File([blob], uid, {
        type: foundImageMimeType,
      });

      (file as any).uid = uid;

      this.beforeImageUpload(file as any);
    } else {
      this.notificationService.showInfoMessage(
        this.translateService.instant(
          'main.materials.upload-err-by-type-buffer'
        )
      );
    }
  }

  public beforeImageUpload = (file: NzUploadFile): boolean => {
    if (this.fileList.length >= 5) {
      return false;
    }

    if (!this.supportedFileTypes.includes(file.type)) {
      this.notificationService.showInfoMessage(
        this.translateService.instant(
          'main.materials.upload-err-by-type'
        )
      );
      return false;
    }

    const isPdf = file.type === 'application/pdf';
    const isTooBigSize = file.size > (this.oneKb * (isPdf ? 2048 : 512));

    if (isTooBigSize) {
      this.notificationService.showInfoMessage(
        this.translateService.instant(
          'main.materials.upload-err-by-size',
          {
            size: Math.ceil(file.size / 1000),
            maxSize: isPdf ? 2048 : 512,
          }
        )
      );
      return false;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      file.url = `${event.target.result}`;
      this.addFile([file]);

      setTimeout(() => {
        this.fileList = [
          ...this.fileList,
        ];
      }, 0);
    };

    reader.readAsDataURL(file as any);

    return false;
  };
}
