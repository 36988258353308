<form nz-form
      [formGroup]="conductLessonFormService.lessonForm"
      nzLayout="vertical">
  <nz-form-item>
    <nz-form-label nzFor="notes">
      {{ 'calendar.conduct-lesson.notes' | translate }}
    </nz-form-label>

    <nz-form-control>
      @if (notesLoader()) {
        <math-loader></math-loader>
      }
            <textarea
              [ngClass]="{ 'ai-highlight': conductLessonFormService.isNoteGenerated() }"
              rows="2"
              formControlName="notes"
              nz-input
              (focus)="isFocusedNotes = true"
              (focusout)="isFocusedNotes = false"
              [placeholder]="'calendar.conduct-lesson.notes' | translate"
              nzAutosize
              maxlength="2048"
            ></textarea>
    </nz-form-control>
    @if (conductLessonFormService.isNoteGenerated()) {
      <span class="ai-alert">
        <label nz-checkbox
               formControlName="approveAiComment">
          {{ 'calendar.conduct-lesson.notes-generated' | translate }}
        </label>
      </span>
    }
  </nz-form-item>

  <nz-form-item>
    <nz-form-label nzFor="links">
      {{ 'calendar.conduct-lesson.links' | translate }}
    </nz-form-label>

    <nz-form-control class="links">
      @for (link of conductLessonFormService.links.controls; track link.value?.name; let index = $index) {
        <nz-tag
          nzMode="closeable"
          (nzOnClose)="onRemoveLink(index)"
          nz-tooltip
          [nzTooltipTitle]="link.value"
        >
          <a [href]="link.value" target="_blank">{{ sliceLinkValue(link.value) }}</a>
        </nz-tag>
      }

      @if (conductLessonFormService.links.controls?.length < 5) {
        @if (!linkInputVisible) {
          <nz-tag class="editable-tag" nzNoAnimation (click)="showLinkInput()">
            <span nz-icon
                  nzType="plus"></span>
            {{ 'calendar.conduct-lesson.new-link' | translate }}
          </nz-tag>
        } @else {
          <input
            #linkInputElement
            nz-input
            nzSize="small"
            type="text"
            [(ngModel)]="linkInputValue"
            [ngModelOptions]="{standalone: true}"
            style="width: 80px;"
            (blur)="handleLinkInputConfirm()"
            (keydown.enter)="handleLinkInputConfirm()"
          />
        }
      }
    </nz-form-control>
  </nz-form-item>

  <div style="margin-bottom: 16px">
    <mathema-info-block [_type]="'info'"
                        [text]="'calendar.conduct-lesson.homework-materials-tip' | translate">
    </mathema-info-block>
  </div>

  <nz-form-item>
    <math-upload-materials></math-upload-materials>
  </nz-form-item>
</form>
