import { Component, forwardRef, inject, Input, input, OnInit, output, signal } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NzModule } from '@modules/nz-module';
import { AIGenerationStatus, GPTMessageResponse } from '@shared/services/gpt.service';
import { Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { JsonPipe } from '@angular/common';

export type GptQueryFunction = (
  input: string,
  options: unknown
) => Observable<GPTMessageResponse>;

@Component({
  selector: 'math-textarea-ai',
  standalone: true,
  imports: [NzModule, TranslateModule],
  templateUrl: './textarea-ai.component.html',
  styleUrl: './textarea-ai.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaAiComponent),
      multi: true,
    },
  ],
})
export class TextareaAiComponent implements OnInit, ControlValueAccessor {
  private readonly translateService = inject(TranslateService);

  public gptQuery = input.required<GptQueryFunction>();
  public preRequestText = input<string>('');
  public maxLength = input<number>(1024);
  public allowEmptyTextToGenerate = input<boolean>(false);
  public gptQueryOptions = input<unknown>({});
  public placeholder = input<string | undefined>(
    this.translateService.instant('ai_textarea.placeholder-default')
  );

  protected _prevAiStatus = AIGenerationStatus.NOT_GENERATED;
  @Input()
  public set prevAiStatus(val: AIGenerationStatus | undefined) {
    this._prevAiStatus = val || AIGenerationStatus.NOT_GENERATED;
    this.isAiGenerated.set(val && val !== AIGenerationStatus.NOT_GENERATED);
  }

  public isAiGenerated = signal(false);
  public isAiEdited = signal(false);
  public showLoader = signal(false);

  private _prevValue = '';
  private _nextValue = '';

  private _value = '';
  @Input()
  public set value(val: string) {
    this._value = val;
  }
  public get value(): string {
    return this._value;
  }

  public generatedText = output<string>();
  public valueChanged = output<string>();
  public generatedValueStatusChanged = output<AIGenerationStatus>();

  private onChange: any = () => {};
  private onTouched: any = () => {};

  public ngOnInit(): void {}

  public prevValue(): void {
    this._nextValue = this._value;
    this._value = this._prevValue;
    this._prevValue = '';
    this.valueChanged.emit(this.value);
    this.onChange(this.value);
    this.generatedValueStatusChanged.emit(AIGenerationStatus.SKIPPED);
  }

  public nextValue(): void {
    this._prevValue = this._value;
    this._value = this._nextValue;
    this._nextValue = '';
    this.valueChanged.emit(this.value);
    this.onChange(this.value);
    if (this.isAiEdited()) {
      this.generatedValueStatusChanged.emit(AIGenerationStatus.EDITED_AFTER_GENERATION);
    } else {
      this.generatedValueStatusChanged.emit(AIGenerationStatus.GENERATED);
    }
  }

  public get isNextEnabled(): boolean {
    return !!this._nextValue.length;
  }

  public get isPrevEnabled(): boolean {
    return !!this._prevValue.length
      || (!this._nextValue.length && this.allowEmptyTextToGenerate());
  }

  public get isGeneratedTextDisplayed(): boolean {
    return (!this.isNextEnabled && this.isPrevEnabled) && this.isAiGenerated();
  }

  protected get isGenerationBlocked(): boolean {
    return (
      !this.allowEmptyTextToGenerate() &&
      (this.isGeneratedTextDisplayed || !this.value.length)
    );
  }

  public writeValue(value: any): void {
    this.value = value;
    this.valueChanged.emit(this.value);

    if (!!this._prevValue.length
      || (
        this._prevAiStatus !== AIGenerationStatus.NOT_GENERATED
        && this._prevAiStatus !== AIGenerationStatus.SKIPPED
      )) {
      this.isAiEdited.set(true);
      this.generatedValueStatusChanged.emit(AIGenerationStatus.EDITED_AFTER_GENERATION);
    }
    else if (!this.isAiGenerated()) {
      this.generatedValueStatusChanged.emit(AIGenerationStatus.NOT_GENERATED);
    }

    this.onChange(this.value);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  protected onAiClick(): void {
    this.showLoader.set(true);

    this.gptQuery()(
      `${this.preRequestText()} ${this.value}`,
      this.gptQueryOptions()
    )
      .pipe(
        take(1),
        finalize(() => this.showLoader.set(false))
      )
      .subscribe((res) => {
        this._prevValue = this._value;
        this._nextValue = '';
        this.writeValue(res.message);
        this.isAiGenerated.set(true);
        this.generatedValueStatusChanged.emit(AIGenerationStatus.GENERATED);
      });
  }

  protected checkForEmpty(): void {
    if (!this.value.length && this.isGeneratedTextDisplayed) {
      this.isAiGenerated.set(false);
    }
  }
}
